<template>
    <div class="app-container">
        <div class="filter-container">
            <!-- 店铺管理员隐藏该选型 -->
            <template v-if="user.user.type !== 'shop'">
                <el-select class="filter-item" filterable v-model="listQuery.shop_id" placeholder="所属店铺" style="width: 200px" clearable>
                    <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
                    </el-option>
                </el-select>
            </template>

            <el-input class="filter-item" v-model="listQuery.keyword" placeholder="请输入姓名/手机号码" style="width: 220px;" clearable/>

            <el-date-picker v-model="dateArr" type="datetimerange" align="right" value-format="yyyy-MM-dd HH:mm:ss" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :default-time="['00:00:00', '23:59:59']" class="filter-item" style="display: inline-flex;" clearable></el-date-picker>

            <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>

            <download-excel :fields="json_fields" :fetch="handleExport" type="xls" name="用户记录.xls" worksheet="用户记录" class="filter-item">
                <el-button type="success" :loading="exportLoading">导出</el-button>
            </download-excel>
        </div>
        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column label="店铺" min-width="110" align="center">
                <template slot-scope="scope">
                    <div>{{ scope.row.shop.shop_name }}</div>
                </template>
            </el-table-column>

            <el-table-column label="姓名" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.user.name }}
                </template>
            </el-table-column>

            <el-table-column label="手机号码" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.user.phone }}
                </template>
            </el-table-column>

            <el-table-column label="类型" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-tag type="warning" v-if="scope.row.type === 1">商家主扫</el-tag>
                    <el-tag type="primary" v-else-if="scope.row.type === 2">用户主扫</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="扫码次数" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.pay_count }}
                </template>
            </el-table-column>

            <el-table-column label="总金额" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.pay_money }}
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";
    import downloadExcel from "vue-json-excel";
    var moment = require("moment");

    export default {
        components: { downloadExcel },
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    shop_id: '',
                    keyword: '',
                    start_time: '',
                    end_time: '',
                },
                shops: [],
                dateArr: null,
                // 导出用户记录
                exportLoading: false,
                json_fields: {
                    店铺: "shop.shop_name",
                    姓名: "user.name",
                    手机号: {
                        field: "user.phone",
                        //自定义回调函数
                        callback: (value) => {
                            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
                        },
                    },
                    类型: {
                        field: "type",
                        //自定义回调函数
                        callback: (value) => {
                            if (value === 1) return "商家主扫";
                            else if (value === 2) return "用户主扫";
                            else return "";

                        },
                    },
                    扫码次数: "pay_count",
                    总金额: "pay_money",
                },
            };
        },
        created() {
            // 默认今天数据
            this.listQuery.start_time = moment().format("YYYY-MM-DD 00:00:00");
            this.listQuery.end_time = moment().format("YYYY-MM-DD 23:59:59");
            this.dateArr = [this.listQuery.start_time, this.listQuery.end_time];
            this.getList();
            this.getShopList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
                this.getShopList();
            },
            dateArr(newVal, oldVal) {
                if (newVal) {
                    this.listQuery.start_time = newVal[0];
                    this.listQuery.end_time = newVal[1];
                } else {
                    this.listQuery.start_time = "";
                    this.listQuery.end_time = "";
                }
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/wallet/liveWalletUserRecord",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            getShopList() {
                request({
                    url: "/api/backend/shop/list",
                    method: "get",
                    params: {
                        page: 1,
                        limit: 1000000,
                        school_id: this.school_id,
                    }
                }).then(response => {
                    this.shops = response.data.data;
                });
            },
            // 导出用户记录
            handleExport() {
                let listQuery = JSON.parse(JSON.stringify(this.listQuery))
                listQuery.page = 1
                listQuery.limit = this.total
                this.exportLoading=true;
                return request({
                    url: "/api/backend/wallet/liveWalletUserRecord",
                    method: "get",
                    params: listQuery,
                    timeout: 10 * 60 * 1000,
                }).then((result) => {
                    if (result.data.total <= 0) {
                        this.$message({
                            type: "warning",
                            message: "暂无记录",
                        });
                        return;
                    }
                    var json_data = result.data.data;
                    return json_data;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.exportLoading=false;
                });
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
